import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import S3 from 'aws-sdk/clients/s3.js';
import Config from '../config/config';
import sendMongoAnalytics from '../utils/sendMongoAnalytics.tsx';
import { useClassContext } from '../context/ClassContext';

const s3 = new S3({
  endpoint: "https://sin1.contabostorage.com/school-attendance",
  accessKeyId: "f21439d90fd89c15e621536b99d3ec8b",
  secretAccessKey: "8acfc04a00cfa868bdfb5b53622fd97d",
  s3BucketEndpoint: true,
  signatureVersion: "v4",
});

const uploadBase64File = async (base64String, fileName, contentType, folder = '') => {
  const base64Data = base64String.replace(/^data:image\/\w+;base64,/, "");
  const binaryString = atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const key = folder ? `${folder}/${fileName}` : fileName;

  const params = {
    Bucket: 'school-attendance',
    Key: key,
    Body: bytes.buffer,
    ContentEncoding: 'base64',
    ContentType: contentType
  };

  try {
    const data = await s3.upload(params).promise();
    console.log('File uploaded successfully:', data.Location);
    return data.Location;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

const readFiles = async (prefix = '') => {
  const params = {
    Bucket: 'school-attendance',
    Prefix: prefix
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    console.log('Files in bucket:', data.Contents);
    return data.Contents;
  } catch (error) {
    console.error('Error reading files:', error);
    throw error;
  }
};


const VideoCapture = () => {
  const { selectedClass } = useClassContext();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const timeoutRef = useRef(null);
  const [name, setName] = useState('');
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState([]);

  useEffect(() => {
    localStorage.getItem('loggedIn') || window.location.replace('/');
  }, []);

  useEffect(() => {
    if (selectedClass) {
      startVideoCapture();
      getAttendanceData();
      // axios
      //   .post(`${Config.BACKEND_URL}/update-class`, { 
      //     class_name: selectedClass,
      //     school_name: localStorage.getItem("schoolId")
      //   })
      //   .then((response) => {
      //     console.log(response.data.message);
      //   })
      //   .catch((error) => {
      //     console.error("Error updating class:", error);
      //   });
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [selectedClass]);

  useEffect(() => {
    getAttendanceData();
  }, [student]);

  const startVideoCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      captureFrames();
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  const captureFrames = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const getBase64Image = () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.save();
        ctx.scale(-1, 1);
        ctx.translate(-canvas.width, 0);

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        ctx.restore();

        const base64 = canvas.toDataURL('image/jpeg');
        sendFrameToBackend(base64);
      }
      timeoutRef.current = setTimeout(getBase64Image, 1000);
    };

    getBase64Image();
  };
  
  const sendFrameToBackend = (imageData) => {
    const formData = new FormData();
    formData.append('class_name', selectedClass);
    formData.append('school_id', localStorage.getItem("schoolId"));
    formData.append('image_data', imageData);

    axios.post(`${Config.BACKEND_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        setStudent(response?.data?.recognized_names || []);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const captureMultipleFrames = async (name) => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('class_name', selectedClass);
    formData.append('school_id', localStorage.getItem("schoolId"));

    const getBase64Images = async () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        for (let i = 0; i < 5; i++) {
          // Flip the canvas context horizontally
          ctx.save();
          ctx.scale(-1, 1);
          ctx.translate(-canvas.width, 0);

          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          ctx.restore();

          const base64 = canvas.toDataURL('image/jpeg');
          formData.append(`image${i}`, base64.split(',')[1]);
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        sendFormDataToBackend(formData);
      } else {
        setTimeout(getBase64Images, 1000);
      }
    };

    getBase64Images();
  };

  const sendFormDataToBackend = async (formData) => {
    setLoading(true);
    try {
      const response = await fetch(`${Config.BACKEND_URL}/register`, {
        method: 'POST',
        body: formData,
      });
      sendMongoAnalytics('student_registered', {})
      toast.success("Registered Student Successfully");
      // Upload the initial frame to S3
      // const base64Image = canvasRef.current.toDataURL('image/png');
      // await uploadBase64File(base64Image, `${name}.png`, 'image/png', selectedClass);
      setName('');
    } catch (error) {
      toast.error("Error occurred while Registering Student");
      console.error('Error sending form data:', error);
    }
    setLoading(false);
  };

  const getAttendanceData = async () => {
    try {
      const response = await axios.get(`${Config.BACKEND_URL}/attendance`, {
        params: {
          school_id: localStorage.getItem("schoolId"),
          class_name: selectedClass    
        }
      });
      const uniqueNames = [...new Set(response.data.map(item => item.name))];
      console.log(response);
      const attendanceData = uniqueNames.map((name) => {
        return response.data.find(item => item.name === name);
      });
      setAttendance(attendanceData);
    } catch (error) {
      console.error('Error getting CSV data:', error);
    }
  };

  const markAbsent = async (studentName) => {
    try {
      const formData = new FormData();
      formData.append('student_name', studentName);
      formData.append('class_name', selectedClass);
      formData.append('school_id', localStorage.getItem("schoolId"));
      const response = await axios.post(`${Config.BACKEND_URL}/mark-absent`, formData);
      getAttendanceData();
      toast.success("Marked Absent Successfully");
    } catch (err) {
      toast.error("Couldn't Mark Absent");
    }
  };

  return (
    <div className='bg-gray-50 text-gray-800 flex flex-col min-h-screen'>
      <div className='flex px-12 gap-10 py-8 h-[calc(100vh-6rem)] flex-row'>
        {/* Left Section - Video Capture */}
        <div className='max-w-[580px] rounded-2xl px-16 bg-white w-full h-full flex flex-col items-center justify-center shadow-sm border border-gray-100'>
          {/* Stats Card */}
          <div className='bg-gray-50 py-2 px-10 rounded-2xl shadow-sm border border-gray-100 mt-2'>
            <p className='flex gap-4 items-center'>
              <span className='flex items-center gap-2'>
                Present: <span className='text-emerald-700 bg-emerald-50 px-4 font-medium py-1.5 rounded-xl'>{attendance.filter((item) => item.attendance === 'Present').length}</span>
              </span>
              <span className='flex items-center gap-2'>
                Absent: <span className='text-red-700 bg-red-50 px-4 font-medium py-1.5 rounded-xl'>{attendance.filter((item) => item.attendance === 'Absent').length}</span>
              </span>
            </p>
          </div>

          <p className='text-center font-semibold text-xl mt-6 mb-6'>Facial Recognition Attendance</p>

          {/* Video Container */}
          <div className='flex-1 w-[18rem] h-[18rem] bg-gray-900 overflow-hidden mx-auto rounded-2xl relative shadow-md'>
            <video className='w-full h-full object-cover rounded-2xl transform -scale-x-100' ref={videoRef} autoPlay playsInline></video>
            <h3 className='absolute text-base font-medium shadow-lg top-4 left-1/2 -translate-x-1/2 rounded-xl bg-white/90 backdrop-blur-sm px-4 py-2 min-w-48 text-gray-800 text-center'>
              {student ? student.join(', ') : "Unknown"}
            </h3>
          </div>
          <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>

          {/* Registration Form */}
          <form className='flex mt-8 w-full gap-2' onSubmit={(e) => { setLoading(true); e.preventDefault(); captureMultipleFrames(name); }}>
            <input 
              type='text' 
              placeholder='Enter Student Name' 
              value={name} 
              onChange={(e) => { setName(e.target.value) }} 
              className='flex-1 py-3 px-4 bg-gray-50 outline-none text-gray-800 rounded-xl border border-gray-200 focus:border-blue-500 transition-colors' 
            />
            <button 
              type='submit' 
              className={`px-6 rounded-xl font-medium ${loading ? 'bg-blue-100 text-blue-700' : 'bg-blue-600 text-white hover:bg-blue-700'} transition-colors`}
            >
              {loading ? 'Adding...' : 'Add'}
            </button>
          </form>
          <p className='text-center text-sm mt-4 text-gray-500'>Please ensure your face is clearly visible in the camera</p>
        </div>

        {/* Right Section - Attendance Grid */}
        <div className='flex-1 bg-white rounded-2xl shadow-sm border border-gray-100 p-8'>
          <div className='grid grid-cols-4 gap-4 overflow-y-auto max-h-full'>
            {attendance.map((item, index) => (
              <div key={index} className='bg-gray-50 aspect-square rounded-xl relative group hover:shadow-md transition-shadow'>
                <img 
                  src={`${Config.BACKEND_URL}/${item.name}.png?school_id=${localStorage.getItem('schoolId')}&class_name=${selectedClass}`} 
                  alt={item.name ? item.name.split('/').pop() : 'Student'} 
                  className='w-full h-full object-cover rounded-xl' 
                />
                <div className='absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-3 rounded-b-xl'>
                  <div className='flex justify-between items-center gap-2'>
                    <p className='text-sm text-white font-medium truncate'>{item.name ? item.name.split('/').pop() : 'Unknown'}</p>
                    <span className={`text-xs font-medium px-2.5 py-1 rounded-lg ${item.attendance === 'Present' ? 'bg-emerald-500' : 'bg-red-500'} text-white`}>
                      {(item?.attendance && typeof item.attendance === 'string') ? item.attendance.split('')[0] : '-'}
                    </span>
                  </div>
                </div>
              </div>
            ))}
            {attendance.length === 0 && (
              <div className='col-span-4 flex items-center justify-center h-full'>
                <p className='text-gray-400 text-lg'>No Attendance Data Available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCapture;
