import React, { createContext, useState, useContext } from 'react';

const ClassContext = createContext();

export const useClassContext = () => {
  const context = useContext(ClassContext);
  if (!context) {
    throw new Error('useClassContext must be used within a ClassProvider');
  }
  return context;
};

export const ClassProvider = ({ children }) => {
  const [selectedClass, setSelectedClass] = useState(() => {
    const batchAccess = JSON.parse(localStorage.getItem("batchAccess"));
    return batchAccess && batchAccess.length > 0 ? batchAccess[0].className : "";
  });

  const handleSelectClass = (newClass) => {
    setSelectedClass(newClass);
  };

  return (
    <ClassContext.Provider value={{ selectedClass, setSelectedClass, handleSelectClass }}>
      {children}
    </ClassContext.Provider>
  );
};