import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import VideoCapture from "./components/VideoCapture";
import sendMongoAnalytics from "./utils/sendMongoAnalytics.tsx";
import Login from "./components/Login";
import { ClassProvider } from "./context/ClassContext";

function App() {
  useEffect(() => {
    sendMongoAnalytics("app_open", {});
  }, []);

  return (
    <Router>
      <ClassProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/home"
              element={
                <>
                  <Navbar />
                  <VideoCapture />
                </>
              }
            />
            {/* Add more routes as needed */}
          </Routes>
        </div>
      </ClassProvider>
    </Router>
  );
}

export default App;
