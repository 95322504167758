import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PasswordLogin = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const navigate = useNavigate();

  const verifyCredentials = async () => {
    if (!agreeToTerms) {
      alert("Please agree to the Terms & Conditions");
      return;
    }
    setSpinner(true);
    try {
      const res = await axios.post(
        "https://tv-app-analytics-backend.betterpw.live/v1/auth/login",
        {
          username: userId,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.data.user.hasAccess) {
        console.log("Credentials verified!", res.data.data.user);
        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("loginDetails", JSON.stringify(res.data.data.user));
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("schoolId", res.data.data.user._id);
        axios.get(`https://tv-app-analytics-backend.betterpw.live/v1/batch-access/school/${res.data.data.user._id}`)
        .then((res) => {
          localStorage.setItem("batchAccess", JSON.stringify(res.data.data));
        }).catch((err) => {
          console.error("Error getting batch access:", err);
        })
        navigate("/home");
      } else {
        alert("Invalid Credentials");
      }
    } catch (err) {
      console.error("Error verifying credentials:", err);
      alert("Invalid Credentials");
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn");
    if (loggedIn) {
      navigate("/home");
    }
  }, [navigate]);

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-50 px-4"
    >
      <div className="bg-white p-8 rounded-2xl shadow-xl max-w-md w-full border border-gray-100">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            Facial Attendance System
          </h1>
          <p className="text-gray-600">
            Enter your credentials to continue
          </p>
        </div>
        <div className="space-y-4">
          <div>
            <input
              className="w-full p-3 border border-gray-200 rounded-lg bg-gray-50 text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
              type="text"
              placeholder="Enter ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
          </div>
          <div>
            <input
              className="w-full p-3 border border-gray-200 rounded-lg bg-gray-50 text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex items-start space-x-2 mt-4">
            <input
              type="checkbox"
              id="terms"
              checked={agreeToTerms}
              onChange={(e) => setAgreeToTerms(e.target.checked)}
              className="mt-1 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 cursor-pointer"
            />
            <label htmlFor="terms" className="text-sm text-gray-600 cursor-pointer">
              I agree to all the{" "}
              <a 
                href="https://www.pw.live/privacy-policy" 
                target="_blank" 
                rel="noopener noreferrer"
                className="font-medium text-blue-600 hover:text-blue-700 transition duration-200"
              >
                Terms & Conditions*
              </a>
            </label>
          </div>
          <button
            className={`w-full p-3 rounded-lg ${
              spinner 
                ? "bg-blue-600" 
                : "bg-blue-500 hover:bg-blue-600 transform hover:-translate-y-0.5"
            } text-white font-medium transition duration-200 shadow-md hover:shadow-lg disabled:opacity-50 disabled:cursor-not-allowed`}
            onClick={verifyCredentials}
            disabled={spinner || !agreeToTerms}
          >
            {spinner ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Loading...
              </span>
            ) : (
              "Login"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordLogin;
