import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoMdHome } from "react-icons/io";
import Config from "../config/config.js";
import sendMongoAnalytics from "../utils/sendMongoAnalytics.tsx";
import { useClassContext } from "../context/ClassContext";

function Navbar() {
  const [classData, setClassData] = useState([]);
  const { selectedClass, setSelectedClass } = useClassContext();

  const handleClassChange = (e) => {
    sendMongoAnalytics("class_changed", {
      oldClass: selectedClass,
      newClass: e.target.value,
    });
    const newClass = e.target.value;
    setSelectedClass(newClass);

    // axios
    //   .post(`${Config.BACKEND_URL}/update-class`, { class_name: newClass, school_name: localStorage.getItem('schoolId') })
    //   .then((response) => {
    //     console.log(response.data.message);
    //   })
    //   .catch((error) => {
    //     console.error("Error updating class:", error);
    //   });
  };

  useEffect(() => {
    setClassData(JSON.parse(localStorage.getItem("batchAccess")));
    setSelectedClass(
      JSON.parse(localStorage.getItem("batchAccess"))[0].className
    );
    console.log(JSON.parse(localStorage.getItem("batchAccess"))[0].className);
  }, []);

  return (
    <div className="flex justify-between items-center px-32 h-20 p-4 bg-white text-gray-800 border-b">
      <div className="flex items-center gap-2">
        <IoMdHome color="#1f2937" size={30} />
        <p className="font-semibold text-lg flex items-center">Attendance System</p>
      </div>
      <div className="flex items-center gap-4">
        <select
          className="text-gray-800 bg-gray-100 w-52 py-1.5 border border-gray-300 rounded-md px-3"
          value={selectedClass}
          onChange={handleClassChange}
        >
          {classData
            .reduce((acc, batch) => {
              if (!acc.some((item) => item.className === batch.className)) {
                acc.push(batch);
              }
              return acc;
            }, [])
            .map((batch) => (
              <option key={batch._id} value={batch.className}>
                {batch.className}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}

export default Navbar;
