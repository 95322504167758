// import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import moment from "moment";

const sendMongoAnalytics = async (eventName: string, data: any) => {

  try {
    const response = await axios.post(
      'https://tv-app-analytics-backend.betterpw.live/v1/events/store-attendance-event',
      {
        school_id: localStorage.getItem('schoolId'),
        name: eventName,
        createdAt: moment().format(),
        className: localStorage.getItem('selectedClass') || 'no_class_selected',
        ...data
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NjlmOWRjOTQzM2YzYWYwZDhkOTgyYTUiLCJ1c2VybmFtZSI6InRlc3QiLCJuYW1lIjoiVGVzdCBTY2hvb2wiLCJpYXQiOjE3MjMxMTIxODQsImV4cCI6MTcyMzk3NjE4NH0.DQFDsyojzYiJ1oZAmgA84PjdL9buGvdLoNYBw_9Pulg'
        }
      }
    );
    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
}

export default sendMongoAnalytics;